import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isProduct: false, //当前在产品页
    productChildIndex: "", //产品在哪个子集
    cabinetIndex: '',//外卖柜还是社区柜
    showMobileRouter: false,//是否展开手机导航
    scrollTop: false,//滚动条是否滚动了200
  },
  getters: {},
  mutations: {
    // 设置手机信息
    setIsProduct(state, value) {
      state.isProduct = value;
      // localStorage.setItem('navTree', JSON.stringify(state.navTree))
    },
    // 设置产品在哪个子集
    setProductChildIndex(state, value) {
      state.productChildIndex = value;
    },
    setCabinetIndex(state, value) {
      state.cabinetIndex = value;
    },
    setShowMobileRouter(state, value) {
      state.showMobileRouter = value;
    },
    setScrollTop(state, value) {
      state.scrollTop = value;
    },
  },
  actions: {},
  modules: {},
});
