<template>
  <div class="mz-footer">
    <div class="left">
      <img src="@/assets/img/gz.png" alt="" />
      <div>公司公众号二维码</div>
    </div>
    <div class="right">
      <div>客服热线：0571-86298857（周一至周五09:00-18:00）</div>
      <div>公司地址：浙江省杭州市滨江区西兴街道月明路66号A座109室</div>
      <div>
        2023&nbsp;杭州合尚山科技有限公司All Rights Reserved<span>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >&nbsp;&nbsp;浙ICP备19002073号-3</a
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mzFooter",
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 480px) {
  .mz-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 149rem;
    min-height: 220px;
    background: #fafafa;
    text-align: center;

    .left {
      margin-right: 50rem;

      img {
        display: block;
        width: 110px;
        height: 110px;
        margin: 0 auto 6px;
      }

      div {
        font-size: 13rem;
        letter-spacing: -0.07em;
        color: #000000;
      }
    }

    .right {
      div {
        font-size: 16rem;
        color: #979797;

        &:nth-child(2) {
          margin: 12rem 0;
        }

        span {
          a {
            color: #979797;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .mz-footer {
    height: 173px;
    background: #fafafa;
    text-align: center;

    .left {
      padding-top: 6px;

      img {
        display: block;
        width: 46px;
        height: 46px;
        margin: 0 auto 6px;
      }

      div {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.07em;
        text-transform: uppercase;
        color: #000000;
      }
    }

    .right {
      margin-top: 11px;
      div {
        font-size: 11px;
        line-height: 15px;
        text-transform: uppercase;
        color: #979797;
        margin-bottom: 6px;

        &:nth-child(3) {
          padding: 0 18px;
        }

        span {
          a {
            color: #979797;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
