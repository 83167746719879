<template>
  <div id="app">
    <div class="header">
      <mzHeader />
    </div>
    <div class="content">
      <router-view />
    </div>
    <mzFooter />
  </div>
</template>

<script>
import store from "./store";
import { mapState } from "vuex";
import mzHeader from "@/components/mzHeader.vue";
import mzFooter from "@/components/mzFooter.vue";

export default {
  name: "App",
  components: {
    mzHeader,
    mzFooter,
  },
  computed: {
    ...mapState({
      qnScrollTop: (state) => state.scrollTop,
    }),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      setTimeout(() => {
        if (this.qnScrollTop) {
          if (scrollTop < 200) {
            store.commit("setScrollTop", false);
          }
        } else {
          if (scrollTop > 200) {
            store.commit("setScrollTop", true);
          }
        }
      }, 300);
    },
  },
};
</script>
<style lang="scss">
#app {
  width: 100%;
  min-height: 100%;
  background: #fff;
  img {
    display: block;
  }
  .header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
  }
}
.animate__animated.animate__bounceInDown {
  --animate-duration: 0.8s;
}
// @media screen and (min-width: 480px) {
// .content {
//   min-height: calc(100vh - 32px - 220px);
// }
// }
@media screen and (max-width: 480px) {
  .content {
    min-height: calc(100vh - 173px);
  }
}
</style>
