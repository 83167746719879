import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/common.scss";
import * as echarts from "echarts";
import animated from "animate.css";

Vue.prototype.$echarts = echarts;
// Vue.prototype.$route = router;

// 注册
Vue.use(ElementUI);
// Vue.use(animated);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
