import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import productRouters from "./product";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "home",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/product",
    name: "product",
    redirect: {
      name: "productIndex",
    },
    component: () => import("../views/product/productView.vue"),
    children: [...productRouters],
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: () =>
      import(
        /* webpackChunkName: "cooperation" */ "../views/CooperationView.vue"
      ),
  },
  {
    path: "/openApi",
    name: "openApi",
    component: () =>
      import(/* webpackChunkName: "openApi" */ "../views/OpenApiView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: "link-active",
  linkExactActiveClass: "link-exact-active",
});

router.beforeEach((to, from, next) => {
  // console.log("to",to)
  // store.commit('setShowMobileRouter', true)
  if (
    to.name == "productIndex" ||
    to.name == "exampleIndex" ||
    to.name == "systemIndex"
  ) {
    store.commit("setIsProduct", true);
    if (to.name == "productIndex") {
      store.commit("setProductChildIndex", "1-1");
    } else if (to.name == "exampleIndex") {
      store.commit("setProductChildIndex", "1-2");
    } else if (to.name == "systemIndex") {
      store.commit("setProductChildIndex", "1-3");
    }
    if (to.query.top) {
      let top = document.documentElement.clientHeight / 1080 * to.query.top
      window.scrollTo(0, top);
    } else {
      // Chrome
      document.body.scrollTop = 0;
      // FireFox
      document.documentElement.scrollTop = 0;
      // Safari
      window.pageYOffset = 0;
      // IE
      window.scrollTo(0, 0);
    }
    if (to.query.cabinetIndex) {
      store.commit("setCabinetIndex", to.query.cabinetIndex);
    }else{
      store.commit("setCabinetIndex", '4');
    }
  } else {
    store.commit("setIsProduct", false);
    // Chrome
    document.body.scrollTop = 0;
    // FireFox
    document.documentElement.scrollTop = 0;
    // Safari
    window.pageYOffset = 0;
    // IE
    window.scrollTo(0, 0);
  }
  // store.commit('setShowMobileRouter', false)
  next();
});

export default router;
