<template>
  <div style="position: relative">
    <div class="mz-header-pc">
      <div class="header-wrap">
        <router-link to="/home">
          <img class="icon-img" src="@/assets/img/logo.png" alt="盟站"
        /></router-link>
        <router-link class="item" active-class="active" to="/home"
          >首页</router-link
        >
        <div
          :class="['item', 'item2', qnIsProduct ? 'active' : '']"
          active-class="active"
          to="/product"
          @mousemove="showChildren = true"
          @mouseleave="
            (showChildren = false),
              (childrenIndex = qnProductChildIndex
                ? qnProductChildIndex
                : '1-1')
          "
        >
          公司产品
          <transition
            enter-active-class="animate__animated animate__fadeInDown"
            leave-active-class=""
          >
            <div v-show="showChildren" class="children-wrap">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class=""
              >
                <div v-show="showChildren">
                  <div class="title-wrap">
                    <router-link
                      class="title-wrap-item"
                      active-class="choose-child"
                      :to="{ path: '/productIndex', query: { childrenIndex } }"
                      @mousemove.native="childrenIndex = '1-1'"
                      @click.native="
                        (showChildren = false), (childrenIndex = '1-1')
                      "
                      >产品介绍</router-link
                    >
                    <router-link
                      class="title-wrap-item"
                      active-class="choose-child"
                      to="/exampleIndex"
                      @mousemove.native="childrenIndex = '1-2'"
                      @click.native="
                        (showChildren = false), (childrenIndex = '1-2')
                      "
                      >系统功能</router-link
                    >
                    <router-link
                      class="title-wrap-item"
                      active-class="choose-child"
                      to="/systemIndex"
                      @mousemove.native="childrenIndex = '1-3'"
                      @click.native="
                        (showChildren = false), (childrenIndex = '1-3')
                      "
                      >应用案例</router-link
                    >
                  </div>
                  <div class="content-wrap">
                    <div v-if="childrenIndex == '1-1'" class="children-1">
                      <div class="img-wrap">
                        <router-link
                          class="img-wrap-item"
                          :to="{
                            path: '/productIndex',
                            query: { cabinetIndex: '0', top: 926 },
                          }"
                          @click.native="showChildren = false"
                        >
                          <img src="@/assets/img/product/1/2.png" alt="" />
                          <div>基于多功能智能社<br />区团购柜</div>
                        </router-link>
                        <router-link
                          class="img-wrap-item"
                          :to="{
                            path: '/productIndex',
                            query: { cabinetIndex: '1', top: 926 },
                          }"
                          @click.native="showChildren = false"
                        >
                          <img src="@/assets/img/product/1/1.png" alt="" />
                          <div>基于外卖平台多功<br />能组合柜</div>
                        </router-link>
                        <router-link
                          class="img-wrap-item"
                          :to="{
                            path: '/productIndex',
                            query: { cabinetIndex: '2', top: 926 },
                          }"
                          @click.native="showChildren = false"
                        >
                          <img src="@/assets/img/product/1/3.png" alt="" />
                          <div>基于企业服务存包<br />柜</div>
                        </router-link>
                      </div>
                      <router-link
                        class="btn"
                        :to="{ path: '/productIndex', query: { top: 926 } }"
                        @click.native="
                          (showChildren = false), (childrenIndex = '1-1')
                        "
                      >
                        <img
                          class="more-img"
                          src="@/assets/img/product/1/4.png"
                          alt=""
                        />
                        更多产品
                      </router-link>
                    </div>
                    <div v-else-if="childrenIndex == '1-2'" class="children-2">
                      <router-link
                        class="children-2-item"
                        v-for="(cItem2, cIndex2) in childrenList2"
                        :key="cIndex2"
                        :to="{
                          path: '/exampleIndex',
                          query: {},
                        }"
                        @click.native="showChildren = false"
                      >
                        <div class="title">{{ cItem2.title }}</div>
                        <div class="children-content">
                          <div
                            v-for="(cItem2c, cIndex2c) in cItem2.children"
                            :key="cIndex2c"
                          >
                            {{ cItem2c }}
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div v-else-if="childrenIndex == '1-3'" class="children-3">
                      <div class="children-2-item-wrap">
                        <router-link
                          class="children-2-item children-3-item"
                          v-for="(cItem2, cIndex2) in childrenList3"
                          :key="cIndex2"
                          :to="{
                            path: '/systemIndex',
                            query: { top: cItem2.top },
                          }"
                          @click.native="showChildren = false"
                        >
                          <div class="title">{{ cItem2.title }}</div>
                          <div class="children-content">
                            <div
                              v-for="(cItem2c, cIndex2c) in cItem2.children"
                              :key="cIndex2c"
                            >
                              {{ cItem2c }}
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <router-link
                        class="btn"
                        :to="{ path: '/systemIndex', query: { top: 563 } }"
                        @click.native="
                          (showChildren = false), (childrenIndex = '1-3')
                        "
                      >
                        <img
                          class="more-img"
                          src="@/assets/img/product/1/4.png"
                          alt=""
                        />
                        查看更多
                      </router-link>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </div>
        <router-link class="item" active-class="active" to="/cooperation"
          >合作</router-link
        >
        <router-link class="item" active-class="active" to="/openApi"
          >开放平台</router-link
        >
        <router-link class="item" active-class="active" to="/about"
          >关于我们</router-link
        >
        <div v-show="showChildren" class="children-mask"></div>
      </div>
    </div>
    <div class="mz-header-mobile">
      <div
        v-if="!showMHeader"
        :class="['m-header-icon-wrap', qnScrollTop ? 'scrollTop-c' : '']"
      >
        <div class="btn" @click="showMHeader = true">
          <img class="to-right" src="@/assets/img/toRight.png" />
        </div>
      </div>
      <div v-if="showMHeader" class="router-wrap">
        <div class="top">
          <img class="icon-img" src="@/assets/img/logo.png" alt="盟站" />
          <img
            class="to-close"
            src="@/assets/img/x.png"
            @click="showMHeader = false"
          />
        </div>
        <div class="router-wrap-content">
          <router-link
            class="item"
            active-class="active"
            to="/home"
            @click.native="(showMHeader = false), (showChildren2 = false)"
            >首页</router-link
          >
          <div class="item-wrap">
            <div class="item">
              <div
                :class="['item-title', qnIsProduct ? 'active' : '']"
                @click="showChildren = !showChildren"
              >
                公司产品<i
                  :class="[
                    showChildren || qnIsProduct
                      ? 'el-icon-arrow-down'
                      : 'el-icon-arrow-right',
                  ]"
                ></i>
              </div>
              <div v-if="showChildren || qnIsProduct" class="item-chidren">
                <div class="item-c-item">
                  <div
                    class="item-c-i-title"
                    @click="showChildren2 = !showChildren2"
                  >
                    产品介绍<i
                      :class="[
                        showChildren2
                          ? 'el-icon-arrow-down'
                          : 'el-icon-arrow-right',
                      ]"
                    ></i>
                  </div>
                  <div v-if="showChildren2" class="item-c-i-chidren">
                    <router-link
                      :class="[
                        'item-c-item',
                        qnIsProduct && qnCabinetIndex == '0' ? 'active' : '',
                      ]"
                      :to="{
                        path: '/productIndex',
                        query: { cabinetIndex: '0' },
                      }"
                      @click.native="
                        (showChildren = false), (showMHeader = false)
                      "
                    >
                      基于多功能智能社区团购柜
                    </router-link>
                    <router-link
                      :class="[
                        'item-c-item',
                        qnIsProduct && qnCabinetIndex == '1' ? 'active' : '',
                      ]"
                      :to="{
                        path: '/productIndex',
                        query: { cabinetIndex: '1' },
                      }"
                      @click.native="
                        (showChildren = false), (showMHeader = false)
                      "
                    >
                      基于外卖平台多功能组合柜
                    </router-link>
                    <router-link
                      :class="[
                        'item-c-item',
                        qnIsProduct && qnCabinetIndex == '2' ? 'active' : '',
                      ]"
                      :to="{
                        path: '/productIndex',
                        query: { cabinetIndex: '2' },
                      }"
                      @click.native="
                        (showChildren = false), (showMHeader = false)
                      "
                    >
                      基于企业服务存包柜
                    </router-link>
                  </div>
                </div>
                <router-link
                  class="item-c-item"
                  active-class="choose-child"
                  to="/exampleIndex"
                  @click.native="
                    (showChildren = false),
                      (childrenIndex = '1-2'),
                      (showMHeader = false),
                      (showChildren2 = false)
                  "
                  >系统功能</router-link
                >
                <router-link
                  class="item-c-item"
                  active-class="choose-child"
                  to="/systemIndex"
                  @click.native="
                    (showChildren = false),
                      (childrenIndex = '1-3'),
                      (showMHeader = false),
                      (showChildren2 = false)
                  "
                  >应用案例</router-link
                >
              </div>
            </div>
          </div>
          <router-link
            class="item"
            active-class="active"
            to="/cooperation"
            @click.native="(showMHeader = false), (showChildren2 = false)"
            >合作</router-link
          >
          <router-link
            class="item"
            active-class="active"
            to="/openApi"
            @click.native="(showMHeader = false), (showChildren2 = false)"
            >开放平台</router-link
          >
          <router-link
            class="item"
            active-class="active"
            to="/about"
            @click.native="(showMHeader = false), (showChildren2 = false)"
            >关于我们</router-link
          >
        </div>
      </div>
    </div>
    <div
      v-if="showMHeader"
      class="router-wrap-mask"
      @click="showMHeader = false"
    ></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "mzHeader",
  data() {
    return {
      showMHeader: false,
      showChildren: false,
      showChildren2: false,
      childrenIndex: "",
      nowChildren: [
        { value: "1-1", lable: "产品介绍", path: "productIndex" },
        { value: "1-2", lable: "系统功能", path: "systemIndex" },
        { value: "1-3", lable: "应用案例", path: "exampleIndex" },
      ],
      childrenList2: [
        {
          title: "微信小程序",
          children: ["盟站外卖柜", "盟站智能柜", "盟站社区柜", "盟站运营"],
        },
        {
          title: "盟站saas系统",
          children: ["设备运维", "订单管理", "分润结算", "客服支撑"],
        },
      ],
      childrenList3: [
        {
          title: "外卖柜合作案例",
          top: "563",
          children: [
            "吉利大厦",
            "浙商证券大厦",
            "浙水大厦",
            "杭州鼎盛大厦",
            "电魂大厦",
          ],
        },
        {
          title: "存包柜合作案例",
          top: "1250",
          children: [
            "印象江南水上乐园",
            "商丘欢乐谷",
            "梅山湾冰雪大世界 ",
            "月牙湖风景区",
            "天龙山飞鹰堡漂流",
          ],
        },
        {
          title: "社区柜合作案例",
          top: "1800",
          children: [
            "温兴风情苑",
            "景冉佳园",
            "盛元慧谷  ",
            "中海·御道",
            "江南文苑",
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      qnIsProduct: (state) => state.isProduct,
      qnProductChildIndex: (state) => state.productChildIndex,
      qnCabinetIndex: (state) => state.cabinetIndex,
      qnShowMobileRouter: (state) => state.showMobileRouter,
      qnScrollTop: (state) => state.scrollTop,
    }),
  },
  watch: {
    qnProductChildIndex: {
      handler(newVal) {
        this.childrenIndex = newVal ? newVal : "1-1";
      },
      immediate: true,
    },
    qnShowMobileRouter: {
      handler(newVal) {
        console.log("newVal", newVal);
        if (newVal == false) {
          this.showMHeader = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    // toProduct() {
    //   this.showChildren = false;
    //   this.$router.push({ path: "/productIndex", query: { top: 366 } });
    // },
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 480px) {
  .animate__animated.animate__fadeInDown {
    --animate-duration: 0.3s;
  }
  .animate__animated.animate__fadeIn {
    --animate-duration: 1.3s;
  }
  .animate__animated.animate__fadeOut {
    --animate-duration: 1.5s;
  }
  .mz-header-pc {
    box-shadow: 0rem 1px 0rem rgba(0, 0, 0, 0.25);
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早期浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
    .header-wrap {
      display: flex;
      align-items: flex-end;
      width: 1600rem;
      height: 88rem;
      min-height: 32px;
      margin: 0 auto;
      .icon-img {
        width: 90rem;
        min-width: 45px;
        height: auto;
        margin: 0 70rem 16rem 0;
      }
      .item {
        z-index: 11;
        display: block;
        width: 126rem;
        min-width: 50px;
        // display: inline-block;
        font-weight: 400;
        font-size: 24rem;
        letter-spacing: 0.02em;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 19rem;
        margin-right: 40rem;
        margin-bottom: -2rem;
        cursor: pointer;
      }
      .active {
        // z-index: 11;
        border-bottom: 3rem solid #000;
        color: #000000;
        font-weight: 700;
      }
      .item2 {
        // z-index: 11;
        &:hover {
          .children-mask {
            display: block;
          }
        }
      }
    }
    .children-mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 88rem;
      height: calc(100vh - 88rem);
      background: rgba(0, 0, 0, 0.5);
      z-index: 8;
    }
    .children-wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 88rem;
      z-index: 9;
      width: 100%;
      height: 529rem;
      background: #fff;
      font-weight: 400;
      box-shadow: 0rem -1rem 0rem rgba(0, 0, 0, 0.25);
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        .title-wrap-item {
          padding: 27rem 0 12rem;
          font-size: 24rem;
          line-height: 34rem;
          color: rgba(0, 0, 0, 0.75);
          cursor: pointer;
          position: relative;
          margin-bottom: 17rem;

          &:nth-child(2) {
            margin: 0 301rem 17rem;
          }
          &:before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 0;
            height: 2px;
            background: rgba(0, 0, 0, 0.65);
            transition: all 0.3s;
          }
          &:hover:before {
            width: 100%;
            left: 0;
            right: 0;
          }
        }
        .choose-child {
          font-weight: 700;
          color: #000000;
          // margin-bottom: 17rem;
        }
      }
    }
    .content-wrap {
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 237rem;
        height: 64rem;
        background: #f9f9f9;
        border-radius: 28.5rem;
        margin: 45rem auto 0;
        cursor: pointer;
        font-size: 20rem;
        color: rgba($color: #000000, $alpha: 0.75);

        .more-img {
          width: 30rem;
          height: 30rem;
          margin: 0 8rem 0 0;
        }
      }
      .children-1 {
        font-size: 20rem;
        line-height: 28rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.75);
        .img-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          .img-wrap-item {
            width: 249rem;
            height: 277rem;
            background: #f9f9f9;
            border-radius: 6rem;
            color: rgba(0, 0, 0, 0.75);
            &:nth-child(2) {
              margin: 0 73rem;
            }
            img {
              height: 134rem;
              width: auto;
              margin: 33rem auto;
            }
          }
        }
      }
      .children-2 {
        display: flex;
        justify-content: center;
      }
      .children-3 {
        position: relative;
        .children-2-item-wrap {
          display: flex;
          justify-content: center;
        }
        .btn {
          margin-top: 26rem;
        }
      }
      .children-2-item {
        text-align: left;
        &:nth-child(1) {
          margin-right: 303rem;
        }
        .title {
          font-size: 20rem;
          line-height: 28rem;
          color: rgba(0, 0, 0, 0.75);
          border-bottom: 1rem solid rgba(0, 0, 0, 0.25);
          padding: 11rem 0 17rem;
        }
        .children-content {
          padding-top: 26rem;

          div {
            margin-bottom: 16rem;
            font-size: 20rem;
            line-height: 28rem;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .children-3-item {
        &:nth-child(1) {
          margin: 0;
        }
        &:nth-child(2) {
          margin: 0 223rem;
        }
      }
    }
  }
  .mz-header-mobile {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .mz-header-pc {
    display: none;
  }
  .mz-header-mobile {
    .m-header-icon-wrap {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 36px;
      .btn {
        position: absolute;
        top: 0px;
        right: 0px;
        padding: 10px 15px;

        .to-right {
          width: 22px;
          height: 18px;
        }
      }
    }

    .scrollTop-c {
      background: #fff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    }

    .router-wrap {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9;
      width: 323px;
      height: 100vh;
      background: #ffffff;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 19px 12px 29px;
        .icon-img {
          width: 60px;
          height: auto;
        }
        .to-close {
          width: 20px;
          height: 20px;
        }
      }
      .router-wrap-content {
        div {
          min-height: 22px;
          font-size: 16px;
          line-height: 22px;
          margin: 24px 0;
        }
        .item {
          display: block;
          width: 100%;
          letter-spacing: 0.02em;
          color: #000000;
          // margin: 24px 0;
          padding: 0 24px 0 30px;
          min-height: 22px;
          font-size: 16px;
          line-height: 22px;
          margin: 24px 0;

          i {
            font-size: 20px;
          }

          .item-title,
          .item-c-i-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .item-chidren {
            padding-left: 19px;
            border-bottom: 1px solid #e0e0e0;

            .item-c-item {
              display: block;
              min-height: 22px;
              font-size: 16px;
              line-height: 22px;
              margin: 24px 0;
              color: #000000;
            }

            .item-c-i-chidren {
              padding-left: 19px;
            }
          }
        }
        .active,
        .choose-child {
          font-weight: 700;
          color: #000000;
        }
      }
    }
  }
  .router-wrap-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 8;
  }
}
</style>
